<template>
  <Layout :tituloPagina="'VPN Credenciales | ' + (modo == 'nuevo'? 'Nueva ': 'Edición de ') + 'credencial'">
    <div class="row">
      <div class="col-xxl-4 col-lg-4 col-sm-6">
        <div class="card">
          <h5 class="card-header">
            <div class="bx-pull-right">
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo=='nuevo' ? guardar() : actualizar()"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="atras()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            Credenciales
          </h5>
          <div class="card-body">
            <div class="mb-3">
              <label for="identidad" class="form-label">Identidad</label>
              <input
                type="text"
                class="form-control"
                v-model="credencial.identidad"
                placeholder="Identidad"
                id="identidad"
              />
            </div>

            <div class="mb-3">
              <label for="clave" class="form-label">Clave</label>
              <input
                type="password"
                v-model="credencial.clave"
                class="form-control" 
                placeholder="*******"
                autocomplete="off"
                id="clave"
              />
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="atras()">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
            <button class="btn btn-success"
              @click="modo=='nuevo'? guardar(): actualizar()">
              <i class="mdi mdi-content-save"></i>
              {{modo=='nuevo'? 'Guardar': 'Actualizar'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import CredencialVpnSrv from '@/services/vpn/CredencialSrv.js' 

export default {
  name: 'EdicionCredencial',
  components: {
    Layout
  },
  data() {
    return {
      modo: 'nuevo',
      credencial: {
        id: null,
        identidad: '',
        clave: ''
      }
    }
  },
  created() {
    let self = this

    if ( this.$route.path.indexOf('nuevo') == -1 ) this.modo = 'edicion'

    if ( this.modo == 'edicion' ) self.cargarCredencial()
  },

  methods: {
    actualizar() {
      let self = this

      CredencialVpnSrv.actualizar(self.credencial).then(response => {
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        self.mostrarMensajeError(error) 
        console.log(error.response.data)
      })
    },

    atras() {
      this.$router.go(-1)
    },


    cargarCredencial() {
      let self = this
      let idCredencial = self.$route.params.id

      self.credencial.id = idCredencial

      CredencialVpnSrv.credencialJSON(idCredencial).then(response => {
        Object.assign(self.credencial, response.data)
      }).catch(error => {
        self.mostrarMensajeError(error) 
        console.log(error.response.data)
      })
    },

    guardar() {
      let self = this

      CredencialVpnSrv.guardar(self.credencial).then(response => {
        iu.msg.success('Se guardó correctamente')
        self.atras()
      }).catch(error => {
        self.mostrarMensajeError(error) 
        console.log(error.response.data)
      })
    },

    mostrarMensajeError(error) {
      let data = error.response.data
      let mensaje = data.message
      let errors = data.errors || []

      mensaje += '<br/><br/>'
      Object.keys(errors).forEach(key => {
        mensaje += ' * ' + errors[key] + '<br>'
      })
      iu.msg.error(mensaje)
    }
  },
}
</script>

<style scoped>

</style>